import { sortBy, uniq, flatten, pick, compact, forOwn } from "lodash";
import { OFFER_PRICE_TYPES, PRODUCTS_FILTERS_KEY } from "app/constants";
import { FacetedClassification, FacetedQuery } from "immfacet";
import { fromJS } from "immutable";
import { getDayCount } from "app/utils/utils";

export const computeMonthFilterValues = (products = []) => {
	const months = products.map(product => {
		if (!product.months || product.months.length === 0) {
			return [];
		}
		return product.months || [];
	});

	const sortedMonths = sortBy(flatten(months), "startAt");

	const labels = sortedMonths.map(month => {
		return String(pick(month, "startAt").startAt) || [];
	});

	return uniq(labels);
};

export const computeDestinationFilterValues = (products = []) => {
	if (!products || products.length === 0) {
		return [];
	}

	const countries = products.map(product => {
		return product.country || [];
	});

	return sortBy(uniq(flatten(countries)));
};

export const computeZoneFilterValues = (products = []) => {
	const zones = products.map(product => {
		if (!product.zones || product.zones.length === 0) {
			return [];
		}
		return product.zones || [];
	});
	return sortBy(uniq(flatten(zones)));
};

export const computePeriodFilterValues = (products = []) => {
	const periods = products.map(product => {
		if (!product.periods || product.periods.length === 0) {
			return [];
		}
		return product.periods || [];
	});

	const sortedPeriods = sortBy(flatten(periods), "startAt");

	const labels = sortedPeriods.map(period => {
		return pick(period, "label").label || [];
	});

	return uniq(labels);
};

export const computeBadgeFilterValues = (products = []) => {
	const badges = products.map(product => {
		if (!product.badges || product.badges.length === 0) {
			return [];
		}
		return product.badges || [];
	});

	const firstBadges = badges.map(badgeList => {
		return badgeList[0];
	});

	const sortedBadges = sortBy(flatten(compact(firstBadges)), "label");

	const badgeLabels = sortedBadges.map(badge => {
		return pick(badge, "label").label || [];
	});

	return uniq(badgeLabels);
};

export const computeTopicFilterValues = (products = []) => {
	if (!products || products.length === 0) {
		return [];
	}

	const topics = products.map(product => {
		return product.topics || [];
	});

	return sortBy(uniq(flatten(topics)));
};

export const computeMerchandisingTopicsFilterValues = (products = []) => {
	if (!products || products.length === 0) {
		return [];
	}

	const merchandisingTopics = products.map(product => {
		return product.merchandisingTopics || [];
	});

	return sortBy(uniq(flatten(merchandisingTopics)));
};

export const generateFacetCollection = (products = [], productsFilters = {}) => {
	let facetCollection = new FacetedClassification(fromJS(products));

	facetCollection = facetCollection
		.addFacet(
			PRODUCTS_FILTERS_KEY.TOPIC,
			product => {
				if (!product.get("topics")) {
					return undefined;
				}
				return product.get("topics").toJS();
			},
			{
				multiValue: true,
			}
		)
		.addFieldFacet(PRODUCTS_FILTERS_KEY.MERCHANDISINGS, {
			multiValue: true,
		})
		.addFacet(PRODUCTS_FILTERS_KEY.DESTINATION, product => {
			if (!product.get("country")) {
				return undefined;
			}
			return product.get("country");
		})
		.addFacet(
			PRODUCTS_FILTERS_KEY.ZONE,
			product => {
				if (!product.get("zones")) {
					return undefined;
				}
				return product
					.get("zones")
					.toJS()
					.map(zone => {
						return zone;
					});
			},
			{
				multiValue: true,
			}
		)
		.addFacet(
			PRODUCTS_FILTERS_KEY.CATEGORY,
			product => {
				if (!product.get("merchandisingTopics")) {
					return undefined;
				}
				return product.get("merchandisingTopics").toJS();
			},
			{
				multiValue: true,
			}
		)
		.addFacet(
			PRODUCTS_FILTERS_KEY.BADGE,
			product => {
				if (!product.get("badges")) {
					return undefined;
				}
				return product
					.get("badges")
					.toJS()
					.map(badge => {
						return badge.label;
					});
			},
			{
				multiValue: true,
			}
		)
		.addFacet(
			PRODUCTS_FILTERS_KEY.PERIOD,
			product => {
				if (!product.get("periods")) {
					return undefined;
				}
				return product
					.get("periods")
					.toJS()
					.map(period => {
						return period.label;
					});
			},
			{
				multiValue: true,
			}
		)
		.addFacet(
			PRODUCTS_FILTERS_KEY.MONTH,
			product => {
				if (!product.get("months")) {
					return undefined;
				}
				return product
					.get("months")
					.toJS()
					.map(month => {
						return String(month.startAt);
					});
			},
			{
				multiValue: true,
			}
		)
		.addFacet(PRODUCTS_FILTERS_KEY.START_AT, product => {
			if (!product.get("startAt")) {
				return undefined;
			}
			return String(
				getDayCount(product.get("startAt"), Date.now()) <= 2 &&
					getDayCount(product.get("startAt"), Date.now()) >= 0
			);
		})
		.addFacet(PRODUCTS_FILTERS_KEY.DISCOUNT_PERCENTAGE, product => {
			if (!product.get("fromPriceType")) {
				return undefined;
			}

			return String(
				product.get("fromPriceType").toJS().type ===
					OFFER_PRICE_TYPES.FROM_PRICE_TYPE_SAVE_UP_TO
					? Number(product.get("fromPriceType").toJS().value) >= 50
					: undefined
			);
		})
		.addFacet(PRODUCTS_FILTERS_KEY.END_AT, product => {
			if (!product.get("endAt")) {
				return undefined;
			}

			return String(
				getDayCount(Date.now(), product.get("endAt")) <= 2 &&
					getDayCount(Date.now(), product.get("endAt")) >= 0
			);
		});

	let facetQuery = new FacetedQuery(facetCollection);

	forOwn(productsFilters, (value, key) => {
		// on doit gérer les valeurs en string et boolean (pour startAt et endAt)
		if ((value && value.length > 0) || (value && typeof value === "boolean")) {
			facetQuery = facetQuery.select({
				name: key,
				values: typeof value === "string" || typeof value === "boolean" ? [value] : value,
			});
		}
	});

	return facetQuery;
};
