import { memo } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Timer from "app/pages/.shared/Timer";
import Badge from "app/pages/.shared/Badge";
import BadgeList from "app/pages/.shared/ColoredProductBadge/BadgeList";
import "./ProductTopBar.scss";

const ProductTopBar = ({
	isFlashsale = {},
	badges = [],
	productExpirationHours,
	productExpirationDays,
}) => {
	return (
		isFlashsale && (
			<div className="product-top-bar" data-testid="product-top-bar">
				<Badge>
					<Timer
						hour={productExpirationHours}
						day={productExpirationDays}
						suffix={<FormattedMessage id="timer.left.label" />}
					/>
				</Badge>

				<div className="product-top-bar__badges">
					<BadgeList badges={badges} />
				</div>
			</div>
		)
	);
};

ProductTopBar.propTypes = {
	isFlashsale: PropTypes.bool,
	productExpirationHours: PropTypes.number,
	badges: PropTypes.array,
	productExpirationDays: PropTypes.number,
};

export default memo(ProductTopBar);
